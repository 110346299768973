export const endPoints = {
  twitchUserRegistration: "/users/twitch-register",
  getWhiteListedUsers: "/users/white-listed",
  saveOverlay: "/overlays",
  getSubscriptions: "/subs",
  getVirtualScenes: "/virtualScenes",
  settings: "/settings",
  getAssetById: "/user-content/inventory/getAssetById",
  createPaymentIntent: "/payments/create-payment-intent",
  getCountries: "/payments/get-countries",
  getStates: "/payments/get-states",
  getCities: "/payments/get-cities",
  getAllSubscriptions: "/payments",
  createSubscription: "/payments/create-subscription",
  getCovertedCurrency: "/payments/get-currency-converted",
  addPaymentDetailsToUser: "/payments/add-payment-detail",
  createPaymentMethod: "/payments/create-payment-method",
  attachPaymentMethod: "/payments/attach-payment-method",
  makePaymentMethodDefault: "/payments/make-default-pm",
  getListOfPaymentMethods: "/payments/list-payment-methods",
  getStripeCustomerDetails: "/payments/get-stripe-customer",
  getPaymentMethodDetail: "/payments/payment-method",
  editUser: "/users/edit-user",
  addFreeTrial: "/users/add-free-trial",
  getUser: "/users/get-user",
  detachCard: "/payments/detach-payment-method",
  storeSignedUrlOfMyOverlays: "/downloadedOverlays",
  getSignedUrlOfMyOverlays: "/downloadedOverlays",
  deleteSignedUrlOfMyOverlays: "/downloadedOverlays/delete",
  sendDataToTwitchExtViewers: "/twitchExt/broadcast-extension-events",
  getUserCampaigns: "/campaigns/user",
  activeUserCampaigns: "/userCampaigns/active",
  getRandomAsset: "/campaigns/get-random-asset",
  reportFailedFlag: "/users/reportFailedFlag",
  reportFailedCamera: "/users/reportFailedCamera",
  reportSuccessfulSetup: "/users/reportSuccessfulSetup",
  sendChatMessage: "/sendChatMessage",
};
