import { statics } from "./statics";

export const getImageUrl = (itemName: string) => {
  return require(`../assets/images/${itemName}`);
};
export const getVideoUrl = (itemName: string) => {
  return require(`../assets/videos/${itemName}`);
};
export const apiUrl = (endPoint: string) => {
  return `${statics.BASE_URL}${endPoint}`;
};
export const apiUrlV2 = (endPoint: string) => {
  return `${statics.BASE_URL_V2}${endPoint}`;
};
export const segmentationUrl = (file: string) => {
  return `${statics.BASE_SEGMENTATION_URL}/${file}`;
};
export const getStripeCustomer = () => {
  return JSON.parse(localStorage.getItem("stripeCustomerId") || "");
};
export const getUserInfo = () => {
  return JSON.parse(localStorage.getItem("userinfo") as any);
};
export const getS3ObjectUrl = (bucketName: string, objectKey: string) => {
  const endpoint = `https://${bucketName}.s3.amazonaws.com`;
  return `${endpoint}/${objectKey}`;
};
