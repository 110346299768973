import { Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import StreamfogCam from "./components/dashboard/Camera";
import { useEffect } from "react";
import AlertWidget from "./components/dashboard/AlertWidget";
import InitRouteInfo from "./components/dashboard/InitRouteInfo";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/:id" element={<StreamfogCam />} />
        <Route path="/cam/:id" element={<StreamfogCam />} />
        <Route path="/alerts/:id" element={<AlertWidget />} />
      </Routes>
      <ToastContainer
        position="top-right"
        autoClose={4000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        draggable
        pauseOnHover
        // theme="colored"
        style={{
          fontSize: 14,
          fontFamily: "Poppins, sans-serif",
        }}
      />
    </div>
  );
}

export default App;
