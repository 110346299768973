import axios from "axios";
import { endPoints } from "../../utils/apiEndPoints";
import { apiUrl, apiUrlV2 } from "../../utils/getPaths";
import { useEffect, useMemo, useRef, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import {
  getToaster,
  sendDataFromHereToTwitchExtViewers,
} from "../../utils/general";
import { chatGaugeThemes, statics } from "../../utils/statics";
import { SelfieSegmentation } from "@mediapipe/selfie_segmentation";
import { EventEmitter } from "events";
import "../../TextAnimations.scss";
import styled from "@emotion/styled";
import GolfClash from "../gauge-themes/GolfClash";
import {
  bootstrapCameraKit,
  CameraKit,
  CameraKitSession,
  createVideoSource,
  Injectable,
  Lens,
  LensLaunchData,
  PublicContainer,
  RemoteApiRequest,
  RemoteApiService,
  RemoteApiServices,
} from "@snap/camera-kit";
import "../../chatgauge.scss";
import SnapAttrComponent from "./SnapAttribution";
import { Box, IconButton, Typography } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Close } from "@mui/icons-material";
import { supabase } from "../../supabase-client";

interface CameraDevice {
  deviceId: string;
  groupId: string;
  kind: string;
  label: string;
}

type GpuBuffer = HTMLCanvasElement | HTMLImageElement | ImageBitmap;
interface Results {
  image: GpuBuffer;
  segmentationMask: GpuBuffer;
}

// Define a general interface for the payload
interface Payload {
  [key: string]: any; // This can be more specific based on the actual payload structure
}

// Define an interface for the incoming WebSocket message
interface WebSocketMessage {
  typeOfEvent: string;
  params: Payload;
}

// Define the enum
enum ExtensionType {
  VideoAnim = "twitchExt-playAlert",
  FaceAnim = "twitchExt-playAlert",
  FaceAvatar = "twitchExt-modelLoaded",
}

enum EventTypes {
  UPDATE_SETTINGS = "updateSettings",
  SHOW_EXT_PLAYER_INFO = "show-extension-player-info",
  HIDE_EXT_PLAYER_INFO = "hide-extension-player-info",
  CALIB_ADDED = "calibrationImageAdded",
  CALIB_REMOVED = "calibrationImageRemoved",
  CHAT_TO_GAUGE_THEME = "chatToGaugeTheme",
  CAMPAIGN_ACTIVITY = "campaignActivity",
  CHAT_MESSAGE = "chatMessage",
  SNAPCHAT_FILTER = "snapchatFilter",
  REMOVE_SNAPLENS = "removeSnapLens",
}

const EXTENSION_ORIGIN = "extension";

enum OriginTypes {
  ADS_CAMPAIGNS = "ads-campaigns",
  ADMIN = "admin",
}

// Define the mapping type with index signature
interface EventActions {
  [key: string]: (payload: any) => void; // No callback needed in parameters
}

type CameraResolution = [width: number, height: number];

const messageTempInit = { type: "", message: "" };

const StreamfogCam = () => {
  const { id } = useParams();
  const twitchId = id?.split("&")[1];
  const [searchParams] = useSearchParams();
  const origin = searchParams.get("origin");
  const argumentIsMissing = useRef<boolean | null>(true);
  const selfieSegmentation = useRef<any>(null);
  const isDrawingRef = useRef<boolean>(false);
  const maskData = useRef<any>(null);
  const segmentationRunning = useRef<boolean>(false);
  const [allCameraDevices, setAllCameraDevices] = useState<CameraDevice[]>([]);
  const [showPlayerInfo, setShowPlayerInfo] = useState(false);
  const obsControlLevelRef = useRef<number>(0);
  const userCameraVideoRef = useRef<HTMLVideoElement>(null);
  const snapCanvasRef = useRef<HTMLCanvasElement>(null);
  const calibrationImage = useRef<HTMLImageElement>(null);
  const foregroundVideoRef = useRef<HTMLVideoElement>(null);
  const messageTempDivRef = useRef<HTMLDivElement>(null);
  const chatGaugeRef = useRef<HTMLDivElement>(null);
  const extensionPlayerInfoRef = useRef<HTMLDivElement>(null);
  const backgroundVideoRef = useRef<HTMLVideoElement>(null);
  let videoDelay = useRef<number>(0.0);
  const loopVideoAnim = useRef<boolean>(false);
  const obsSceneBlocker = useRef<boolean>(false);

  const [snapSession, setSnapSession] = useState<CameraKitSession>();
  const [cameraKit, setCameraKit] = useState<CameraKit>();
  const [showSnapAttribution, setShowSnapAttribution] =
    useState<boolean>(false);

  const segmentationInterval = useRef<NodeJS.Timeout | null>(null);
  const backgroundVideoCanvasRef = useRef<HTMLCanvasElement>(null);
  let backgroundVideoCanvasCtx =
    backgroundVideoCanvasRef.current &&
    backgroundVideoCanvasRef.current.getContext("2d");
  const [socket, setSocket] = useState<WebSocket | null>(null);
  const [notReadable, setNotReadable] = useState<boolean>(false);
  const [animHasForeground, setAnimHasForeground] = useState<boolean>(false);
  const [animHasBackround, setAnimHasBackground] = useState<boolean>(false);
  const [foregroundReady, setForegroundReady] = useState<boolean>(false);
  const [backgroundReady, setBackgroundReady] = useState<boolean>(false);
  const [foregroundVideoEnded, setForegroundVideoEnded] =
    useState<boolean>(false);
  const [backgroundVideoEnded, setBackgroundVideoEnded] =
    useState<boolean>(false);
  const [permissionDenied, setPermissionDenied] = useState<boolean>(false);
  const [messageTemplate, setMessageTemplate] = useState(messageTempInit);
  const [editorSettings, setEditorSettings] = useState<any>({
    fontFamily: "Poppins",
    fontSize: "Medium|11|40",
    fontThickness: "Regular|400",
    effect: "Night_Lights_Effect",
    textPosition: { x: "0", y: "0" },
    imagePosition: { x: "0", y: "0" },
    letterSpacing: "0px",
    imagePlacement: "block*top",
    imageSize: "Medium|30px|65px",
    color: "#ffffff",
    stroke: null,
  });
  const [initialLensShown, setInitialLensShown] = useState<boolean>(false);
  const [activeSnapfilter, setActiveSnapFilter] = useState<{
    id: string;
    snapLensTime: number;
    launchParams: any;
  }>({ id: "", snapLensTime: 0, launchParams: {} });
  const [brandImgSrc, setBrandImgSrc] = useState<string>("");
  const [extensionPlayerInfo, setExtensionPlayerInfo] = useState<
    {
      name: string;
      playTitle: string;
      playType: string;
      thumbnail: string;
    }[]
  >([]);
  const [camLabel, setCamLabel] = useState<string>("");
  const [camFPS, setCamFPS] = useState<number>(0);
  const [camWidth, setCamWidth] = useState<number>(1920);
  const [camHeight, setCamHeight] = useState<number>(1080);
  const useObsTransitionRef = useRef<boolean>(false);
  const originSceneRef = useRef<string>("");
  const targetSceneRef = useRef<string>("");
  const [segFPS, setSegFPS] = useState<number>(0);
  const [userCampaigns, setUserCampaigns] = useState<any[]>([]);
  const timeouts: { [key: number]: NodeJS.Timeout } = {};
  const customImageTexture = useRef<string>("");

  const [newSettings, setNewSettings] = useState<boolean>(false);

  const completionEmitter = new EventEmitter();

  const [gaugeValue, setGaugeValue] = useState<number>(-1);
  const gaugeMilestones = useRef<any[]>([]);
  const activeGaugeMilestoneIdx = useRef<number>(0);
  const chatToGaugeMultiplier = useRef<number>(1);
  const gaugeRegisteredChatters = useRef<string[]>([]);
  const [chatToGauge, setChatToGauge] = useState({
    isThemeHidden: false,
    theme: "",
    themePositions: null,
  });

  const videoActionQueue = useRef<
    { action: (payload: any) => void; payload: any }[]
  >([]);
  const isVideoActionExecuting = useRef<boolean>(false);

  const videoEventActions: EventActions = {
    playVideoAnim: handlePlayVideoAnim,
  };

  const renderActionQueue = useRef<
    { action: (payload: any) => void; payload: any }[]
  >([]);

  const permanentRenderAction = useRef<{
    action: (payload: any) => void;
    payload: any;
  }>();
  const isRenderActionExecuting = useRef<boolean>(false);

  // Function to add actions to the queue
  function enqueueAction(
    source: string,
    action: (payload: any) => void,
    payload: any
  ) {
    if (
      useObsTransitionRef.current &&
      !isVideoActionExecuting.current &&
      !isRenderActionExecuting.current
    ) {
      handleSwitchObsScene(
        originSceneRef.current,
        targetSceneRef.current,
        true
      );
    }

    if (source === "video") {
      videoActionQueue.current.push({ action, payload });
      executeNextVideoAction();
    }
  }

  async function broadcastToTwitch(payload: any, type: string) {
    await sendDataFromHereToTwitchExtViewers(id as string, type, payload);
    setExtensionPlayerInfo([
      {
        name: payload?.viewer || "Annonymous",
        playTitle: payload?.overlayTitle,
        playType: payload?.playType,
        thumbnail: payload?.thumbnail,
      },
      ...extensionPlayerInfo,
    ]);
  }

  // Function to execute the next action in the queue
  async function executeNextVideoAction() {
    if (
      !isVideoActionExecuting.current &&
      videoActionQueue.current.length > 0
    ) {
      isVideoActionExecuting.current = true;
      const { action, payload } = videoActionQueue.current.shift()!;
      if (payload?.origin && payload?.origin === EXTENSION_ORIGIN) {
        await broadcastToTwitch(payload, ExtensionType.VideoAnim);
      }
      action(payload);
    }
  }

  async function executeNextRenderAction() {
    if (
      !isRenderActionExecuting.current &&
      renderActionQueue.current.length > 0
    ) {
      isRenderActionExecuting.current = true;
      const { action, payload } = renderActionQueue.current.shift()!;
      action(payload);
    } else if (
      !isRenderActionExecuting.current &&
      permanentRenderAction.current !== undefined
    ) {
      const { action, payload } = permanentRenderAction.current;
      action(payload);
    }
  }

  // Listening for the 'completed' event to trigger the next action
  completionEmitter.on("videoCompleted", () => {
    isVideoActionExecuting.current = false;
    setTimeout(() => {
      executeNextVideoAction();
      if (useObsTransitionRef.current) {
        if (
          !isVideoActionExecuting.current &&
          !isRenderActionExecuting.current
        ) {
          handleSwitchObsScene(
            targetSceneRef.current,
            originSceneRef.current,
            false
          );
        }
      }
    }, 3000);
  });

  // Listening for the 'completed' event to trigger the next action
  completionEmitter.on("renderCompleted", () => {
    isRenderActionExecuting.current = false;
    setTimeout(() => {
      executeNextRenderAction();
      if (useObsTransitionRef.current) {
        if (
          !isRenderActionExecuting.current &&
          !isRenderActionExecuting.current
        ) {
          handleSwitchObsScene(
            targetSceneRef.current,
            originSceneRef.current,
            false
          );
        }
      }
    }, 3000);
  });

  function handleUpdateSettings(payload: Payload) {
    setCamLabel(payload.inputCameraLabel);
    useObsTransitionRef.current = payload.transition;
    originSceneRef.current = payload.originScene;
    targetSceneRef.current = payload.targetScene;
    setCamFPS(payload.fps);
    setSegFPS(payload.segmentation);
    const parsedResolution = payload.camResolutions
      .split(",")
      .map(Number) as CameraResolution;
    setCamWidth(parsedResolution[0]);
    setCamHeight(parsedResolution[1]);
    setShowPlayerInfo(payload.twitchExtensionSettings?.showPlayerInfo);
    setNewSettings(true);
  }

  const onSegmentationResult = async (results: Results) => {
    maskData.current = results.segmentationMask;
  };

  const initSegmentation = () => {
    return new Promise(async (resolve, reject) => {
      try {
        selfieSegmentation.current = new SelfieSegmentation({
          //@ts-ignore
          locateFile: (file) => {
            return `${process.env.REACT_APP_SEGMENTATION_URL}/${file}`;
          },
        });

        selfieSegmentation.current.setOptions({
          modelSelection: 1,
        });

        selfieSegmentation.current.onResults(onSegmentationResult);
        await selfieSegmentation.current.initialize();

        if (userCameraVideoRef.current) {
          await selfieSegmentation.current.send({
            image: userCameraVideoRef.current,
          });
        }

        resolve(true);
      } catch (error) {
        reject(error);
      }
    });
  };

  const segmentationLoop = async () => {
    if (!segmentationRunning.current) {
      if (backgroundVideoCanvasRef.current) {
        backgroundVideoCanvasRef.current.style.display = "block";
      }

      segmentationRunning.current = true;

      segmentationInterval.current = setInterval(async () => {
        if (userCameraVideoRef.current) {
          selfieSegmentation.current.send({
            image: userCameraVideoRef.current,
          });
        }
      }, 1000 / segFPS); // Calculate the interval for the desired FPS based on settings
    }
  };

  const stopSegmentationLoop = () => {
    segmentationRunning.current = false;

    if (backgroundVideoCanvasRef.current) {
      backgroundVideoCanvasRef.current.style.display = "none"; // Hide the canvas
    }
    if (segmentationInterval.current) {
      clearInterval(segmentationInterval.current);
      segmentationInterval.current = null;
    }
  };

  const stopDrawMaskLoop = () => {
    isDrawingRef.current = false;
  };

  const drawMaskLoop = async () => {
    const drawFrame = async () => {
      if (!isDrawingRef.current) return;
      await drawMaskOnVideoSlim();
      requestAnimationFrame(drawFrame);
    };

    drawFrame();
  };

  function drawMaskOnVideoSlim() {
    if (backgroundVideoCanvasCtx === null) {
      backgroundVideoCanvasCtx =
        backgroundVideoCanvasRef.current &&
        backgroundVideoCanvasRef.current.getContext("2d");
    }
    if (segmentationRunning.current) {
      if (maskData.current && backgroundVideoCanvasCtx) {
        // Clear the main canvas
        backgroundVideoCanvasCtx.clearRect(0, 0, camWidth, camHeight);

        if (
          backgroundVideoRef.current &&
          !backgroundVideoRef.current.paused &&
          backgroundVideoRef.current.currentTime > 0.03
        ) {
          // Use the available video for masking

          applyMask(backgroundVideoRef.current);
        }
      }
    } else {
      if (backgroundVideoCanvasCtx) {
        backgroundVideoCanvasCtx.clearRect(0, 0, camWidth, camHeight);
      }
    }

    function applyMask(
      source: HTMLCanvasElement | HTMLVideoElement | HTMLImageElement | null
    ) {
      if (backgroundVideoCanvasCtx && source) {
        // Draw the mask
        backgroundVideoCanvasCtx.filter = `blur(3px)`;
        backgroundVideoCanvasCtx.drawImage(
          maskData.current,
          0,
          0,
          camWidth,
          camHeight
        );
        backgroundVideoCanvasCtx.filter = `none`;
        // Set the composite operation to source-out
        backgroundVideoCanvasCtx.globalCompositeOperation = "source-out";

        // Draw the video or the merged content from temp canvas, which will only appear where the mask isn't
        backgroundVideoCanvasCtx.drawImage(source, 0, 0, camWidth, camHeight);

        // Reset the composite operation for any further drawing
        backgroundVideoCanvasCtx.globalCompositeOperation = "source-over";
      }
    }
  }

  const prepareVideoPlay = async (
    videoElem: HTMLVideoElement,
    videoUrl: string,
    reportReady: React.Dispatch<React.SetStateAction<boolean>>,
    reportDone: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    reportReady(false);
    reportDone(false);
    videoElem.src = videoUrl;
    videoElem.loop = loopVideoAnim.current;
    videoElem.oncanplaythrough = () => {
      reportReady(true);
    };
    videoElem.onended = () => {
      reportDone(true);
    };
  };

  function setToDefault() {
    // setMessageTemplate(messageTempInit);
    // setBrandImgSrc("");
    setExtensionPlayerInfo([]);
  }

  async function handlePlayVideoAnim(payload: Payload) {
    if (!selfieSegmentation.current) {
      await initSegmentation();
    }

    if (payload.foreground && foregroundVideoRef.current) {
      setAnimHasForeground(true);
      prepareVideoPlay(
        foregroundVideoRef.current,
        payload.foreground,
        setForegroundReady,
        setForegroundVideoEnded
      );
    }

    if (payload.background && backgroundVideoRef.current) {
      setAnimHasBackground(true);
      prepareVideoPlay(
        backgroundVideoRef.current,
        payload.background,
        setBackgroundReady,
        setBackgroundVideoEnded
      );
    }

    maybeSetMessageTemplate(payload);
  }

  function maybeSetMessageTemplate(payload: Payload) {
    if (payload?.config?.messageTemplate?.type) {
      let { type, message } = payload.config.messageTemplate;
      if (
        payload.config.eventData &&
        message.includes("{name}") &&
        "name" in payload.config.eventData
      ) {
        message = message.replace("{name}", payload.config.eventData.name);
      }
      console.log(message, payload.config.eventData, "message");
      if (
        payload.config.eventData &&
        (message.includes("{amount}") || message.includes("{raidedViewers}")) &&
        "amount" in payload.config.eventData
      ) {
        if (message.includes("{amount}")) {
          message = message.replace(
            "{amount}",
            payload.config.eventData.amount
          );
        }
        if (message.includes("{raidedViewers}")) {
          message = message.replace(
            "{raidedViewers}",
            payload.config.eventData.amount
          );
        }
      }
      setMessageTemplate({ type, message });
    }
    if (payload?.config?.messageTemplateSettings) {
      const msgTempStngs = payload.config.messageTemplateSettings;
      setEditorSettings(msgTempStngs);
      const setToDefaultTime =
        Number(msgTempStngs.duration ? msgTempStngs.duration : 5) +
        Number(msgTempStngs.delay ? msgTempStngs.delay : 0);
      setTimeout(() => {
        if (messageTempDivRef.current) {
          messageTempDivRef.current.classList.remove("fade-in");
          messageTempDivRef.current.classList.add("fade-out");
        }
        setTimeout(() => {
          setMessageTemplate(messageTempInit);
          setBrandImgSrc("");
          // setToDefault();
        }, 500);
      }, setToDefaultTime * 1000);
    }
    if (payload?.config?.brandImg) {
      setBrandImgSrc(payload.config.brandImg);
    }
  }

  useEffect(() => {
    const resetVideo = (videoRef: React.RefObject<HTMLVideoElement>) => {
      if (videoRef.current) {
        videoRef.current.removeAttribute("src");
        videoRef.current.load();
      }
    };

    const resetBackground = () => {
      resetVideo(backgroundVideoRef);
      setAnimHasBackground(false);
      setBackgroundReady(false);
      setBackgroundVideoEnded(false);
    };

    const resetForeground = () => {
      resetVideo(foregroundVideoRef);
      setAnimHasForeground(false);
      setForegroundReady(false);
      setForegroundVideoEnded(false);
    };

    const resetAll = () => {
      stopDrawMaskLoop();
      stopSegmentationLoop();
      resetBackground();
      resetForeground();
    };

    if (animHasBackround && animHasForeground) {
      if (foregroundVideoEnded) {
        resetVideo(foregroundVideoRef);
      }
      if (backgroundVideoEnded) {
        resetVideo(backgroundVideoRef);
      }
      if (backgroundVideoEnded && foregroundVideoEnded) {
        resetAll();
        setToDefault();
        completionEmitter.emit("videoCompleted");
      }
    } else if (animHasBackround && backgroundVideoEnded) {
      setToDefault();
      resetBackground();
      stopDrawMaskLoop();
      stopSegmentationLoop();
      completionEmitter.emit("videoCompleted");
    } else if (animHasForeground && foregroundVideoEnded) {
      setToDefault();
      resetForeground();
      completionEmitter.emit("videoCompleted");
    }
  }, [foregroundVideoEnded, backgroundVideoEnded]);

  useEffect(() => {
    const playVideoWithDelay = (
      videoRef: React.RefObject<HTMLVideoElement>
    ) => {
      if (videoDelay.current > 0) {
        setTimeout(() => {
          if (videoRef.current) videoRef.current.play();
        }, videoDelay.current * 1000); // delay in milliseconds
      } else {
        if (videoRef.current) videoRef.current.play();
      }
    };

    const handleBackground = () => {
      playVideoWithDelay(backgroundVideoRef);
      segmentationLoop();
      isDrawingRef.current = true;
      drawMaskLoop();
    };

    const handleForeground = () => {
      playVideoWithDelay(foregroundVideoRef);
    };

    if (animHasBackround && animHasForeground) {
      if (foregroundReady && backgroundReady) {
        handleBackground();
        handleForeground();
      }
    } else if (animHasBackround) {
      if (backgroundReady) {
        handleBackground();
      }
    } else if (animHasForeground) {
      if (foregroundReady) {
        handleForeground();
      }
    }
  }, [animHasBackround, animHasForeground, foregroundReady, backgroundReady]);

  const clearAllTimeouts = () => {
    for (const key in timeouts) {
      clearTimeout(timeouts[key]); // Clear the timeout
      console.log("Timeout cleared!!", timeouts[key]);
      delete timeouts[key]; // Optionally, remove the reference from the object
    }
  };

  const sendTwitchChatMessage = async (message: string) => {
    const response = await axios.post(
      `${apiUrlV2(endPoints.sendChatMessage)}`,
      {
        twitchId: twitchId,
        message: message,
      }
    );
    console.log(response);
  };

  const randomAssetPlayForCampaigns = async (campaign: any) => {
    console.log(campaign);
    const randomAsset =
      campaign.assets[Math.floor(Math.random() * campaign.assets.length)];
    const interval = campaign.userConfig?.interval;

    if (interval > 0) {
      console.log(
        "Campaign Random Asset to play on " + interval + " minutes ------->> ",
        randomAsset
      );

      timeouts[interval] = setTimeout(() => {
        if (randomAsset !== null) {
          if (randomAsset.type === "videoAnim") {
            enqueueAction(
              "video",
              handlePlayVideoAnim,
              convertScheduleAssetToEvent(randomAsset)
            );
          }
          if (campaign.automaticChatMessage) {
            sendTwitchChatMessage(campaign.automaticChatMessage);
          }

          const unifiedTemplate = {
            ...campaign?.campaignConfig?.promo_code_settings,
            color:
              campaign?.userConfig?.user_promo_code_color ??
              campaign?.campaignConfig?.promo_code_settings?.color,
          };

          maybeSetMessageTemplate({
            config: {
              messageTemplate: {
                type: "promo-code",
                message: campaign.userConfig?.promo_code
                  ? campaign.campaignConfig.promo_code_template.replace(
                      "{PROMO_CODE}",
                      campaign.userConfig?.promo_code
                    )
                  : "",
              },
              messageTemplateSettings: unifiedTemplate,
              brandImg: campaign.brand_image,
            },
          });
          randomAssetPlayForCampaigns(campaign); // Reschedule for the next interval
        }
      }, interval * 60 * 1000);
    }
  };

  const getActiveUserCampaigns = async () => {
    const { data, error } = await supabase
      .from("userCampaigns")
      .select("*")
      .eq("userId", twitchId)
      .eq("isActive", true)
      .eq("targetChannel", "cam");
    if (error) {
      getToaster("error", error.message);
      return;
    }
    setUserCampaigns(data);
  };

  useEffect(() => {
    if (userCampaigns?.length > 0) {
      userCampaigns.forEach((campaign) => {
        if (campaign.type === "intervalPlay") {
          randomAssetPlayForCampaigns(campaign);
        } else if (
          campaign.type === "chatGauge" &&
          campaign?.campaignConfig?.chatToGaugeTheme
        ) {
          setGaugeValue(0);
          gaugeMilestones.current = campaign.milestones;
          chatToGaugeMultiplier.current =
            campaign.userConfig.chatToGaugeMultiplier || 1;
          activeGaugeMilestoneIdx.current = 0;
          setChatToGauge({
            theme: campaign?.campaignConfig?.chatToGaugeTheme,
            isThemeHidden: campaign?.userConfig?.isThemeHidden ?? false,
            themePositions: campaign?.userConfig?.themePositions ?? null,
          });
        }
      });
    }
  }, [userCampaigns]);

  const getAllSettings = async () => {
    if (
      !origin ||
      (origin &&
        origin !== OriginTypes.ADS_CAMPAIGNS &&
        origin !== OriginTypes.ADMIN)
    ) {
      await axios
        .get(`${apiUrl(endPoints.settings)}/${id?.split("&")[1]}`)
        .then((res) => {
          if (res) {
            console.log(res);
            useObsTransitionRef.current = res.data.transition;
            originSceneRef.current = res.data.originScene;
            targetSceneRef.current = res.data.targetScene;
            setCamLabel(res.data.inputCameraLabel);
            setCamFPS(res.data.fps);
            setSegFPS(res.data.segmentation);
            const parsedResolution = res.data.camResolutions
              .split(",")
              .map(Number) as CameraResolution;
            setCamWidth(parsedResolution[0]);
            setCamHeight(parsedResolution[1]);
            setShowPlayerInfo(
              res.data?.twitchExtensionSettings?.showPlayerInfo
            );
          }
        })
        .catch((error) => {
          console.log(error);
          getToaster("error", "Error while getting camera settings.");
        });
    } else {
      setCamFPS(30);
      setSegFPS(30);
      setCamWidth(640);
      setCamHeight(360);
    }
  };

  useEffect(() => {
    if (camFPS !== 0 && allCameraDevices.length > 0) {
      console.log("got settings");
      startCamera();
    } else {
      const elementsToUpdate = [
        userCameraVideoRef.current,
        foregroundVideoRef.current,
        backgroundVideoRef.current,
        backgroundVideoCanvasRef.current,
        messageTempDivRef.current,
        chatGaugeRef.current,
        calibrationImage.current,
        extensionPlayerInfoRef.current,
        snapCanvasRef.current,
      ];

      elementsToUpdate.forEach((element) =>
        updateElementDimensions(element, 1280, 720)
      );
    }
  }, [camLabel, camFPS, camWidth, camHeight, allCameraDevices, snapSession]);

  const getAssetById = async (
    data: Record<string, any>
  ): Promise<any | null> => {
    try {
      const queryString = new URLSearchParams(data).toString();
      console.log(queryString);
      const response = await axios.get(
        `${apiUrlV2(endPoints.getAssetById)}/${
          id?.split("&")[1]
        }?${queryString}`
      );
      console.log(response);
      return response.data.data;
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  const convertScheduleAssetToEvent = (asset: any) => {
    if (asset.type === "videoAnim") {
      return {
        background: asset.config.background
          ? statics.CLOUDFRONT_BASE_URL + asset.rootPath + "background.webm"
          : "",
        foreground: asset.config.foreground
          ? statics.CLOUDFRONT_BASE_URL + asset.rootPath + "foreground.webm"
          : "",
        config: {},
      };
    }
    return {};
  };

  const getMediaDevices = async () => {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const hasNullLabel = devices.some((device) => device.label === "");

      if (hasNullLabel) {
        await requestAndProcessMediaDevices();
      } else {
        processDevices(devices);
      }
    } catch (error) {
      console.error("Initial device enumeration failed:", error);
      await handleDeviceEnumerationFailure();
    }
  };

  function releaseMediaStream(mediaStream: any) {
    if (mediaStream) {
      mediaStream.getTracks().forEach((track: any) => track.stop());
      mediaStream = null;
    }
  }

  const requestAndProcessMediaDevices = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: true,
        audio: true,
      });
      try {
        const devices = await navigator.mediaDevices.enumerateDevices();
        processDevices(devices);
      } catch (error) {
        console.error("Error during re-enumeration of devices:", error);
      } finally {
        releaseMediaStream(stream);
      }
    } catch (error) {
      console.error("Error accessing media devices:", error);
      handleMediaAccessErrors(error);
    }
  };

  const processDevices = (devices: MediaDeviceInfo[]) => {
    const videoDevices = devices.filter(
      (device) => device.kind === "videoinput"
    );

    if (videoDevices.length > 0) {
      const cameraNames = videoDevices.reduce<{ [key: string]: number }>(
        (acc, device) => {
          acc[device.label] = (acc[device.label] || 0) + 1;
          return acc;
        },
        {}
      );

      const numberedCameraList = videoDevices.map((device) => {
        const cameraCount = cameraNames[device.label];
        const cameraDisplayName =
          cameraCount > 1 ? `${device.label} [${cameraCount}]` : device.label;
        return {
          deviceId: device.deviceId,
          groupId: device.groupId,
          kind: device.kind,
          label: cameraDisplayName,
        };
      });

      setAllCameraDevices(numberedCameraList);
    }
  };

  const updateElementDimensions = (
    element:
      | HTMLVideoElement
      | HTMLCanvasElement
      | HTMLImageElement
      | HTMLDivElement
      | null,
    width: number,
    height: number
  ) => {
    if (element) {
      if (element instanceof HTMLDivElement) {
        element.style.width = `${width}px`; // Set width using CSS style
        element.style.height = `${height}px`; // Set height using CSS style
      } else {
        element.width = width; // Directly set width property
        element.height = height; // Directly set height property
      }
    }
  };

  const handleDeviceEnumerationFailure = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: true,
        audio: true,
      });
      const devices = await navigator.mediaDevices.enumerateDevices();
      processDevices(devices);
    } catch (error) {
      console.error("Failed to access media devices on fallback:", error);
    }
  };

  const handleMediaAccessErrors = async (error: unknown) => {
    // Check if error is an instance of Error
    if (error instanceof Error) {
      const twitchId = id?.split("&")[1];
      if (error.name === "NotAllowedError") {
        setPermissionDenied(true);
        try {
          const response = await axios.post(
            `${apiUrl(endPoints.reportFailedFlag)}/${twitchId}`
          );
        } catch (error) {
          console.log("couldnt report failed flag");
        }
      } else if (error.name === "NotReadableError") {
        setNotReadable(true);
        try {
          const response = await axios.post(
            `${apiUrl(endPoints.reportFailedCamera)}/${twitchId}`
          );
        } catch (error) {
          console.log("couldnt report failed camera");
        }
      }
    } else {
      console.error("Caught error is not an instance of Error:", error);
    }
  };

  const showCalibrationImg = (payload: Payload) => {
    console.log(payload);
    if (calibrationImage.current && "image" in payload) {
      calibrationImage.current.style.display = "block";
      calibrationImage.current.src =
        statics.CLOUDFRONT_BASE_URL + payload.image;
      calibrationImage.current.hidden = false;
    }
  };
  const removeCalibrationImg = () => {
    if (calibrationImage.current) {
      calibrationImage.current.removeAttribute("src");
      calibrationImage.current.style.display = "none";
      calibrationImage.current.hidden = true;
    }
  };

  const handleActivateSnapFilter = (payload: Payload) => {
    let launchParams = {};
    if (payload.launchParams) {
      launchParams = {
        ...(payload.launchParams ?? {}),
      };
    }
    if (payload.customImageTexture) {
      customImageTexture.current = payload.customImageTexture;
    }

    if (payload.hasText) {
      let { type, message } = payload.config.messageTemplate;
      if (message.includes("{name}") && "name" in payload.config.eventData) {
        message = message.replace("{name}", payload.config.eventData.name);
      }
      launchParams = {
        text: message,
      };
    } else {
      maybeSetMessageTemplate(payload);
    }

    setActiveSnapFilter({
      ...activeSnapfilter,
      id: payload.snapId,
      snapLensTime: payload.snapLensTime ?? 0,
      launchParams: launchParams,
    });

    if (payload.origin && payload.origin === EXTENSION_ORIGIN) {
      broadcastToTwitch(payload, ExtensionType.FaceAvatar);
    }
  };

  const handleIncomingChatMessage = (payload: Payload) => {
    if (activeGaugeMilestoneIdx.current >= 0) {
      const activeGaugeMilestone =
        gaugeMilestones.current[activeGaugeMilestoneIdx.current];
      console.log("active milestone", activeGaugeMilestone);
      console.log(activeGaugeMilestone.keyword, payload.message);
      if (
        activeGaugeMilestone &&
        activeGaugeMilestone.keyword === payload.message &&
        !gaugeRegisteredChatters.current.includes(
          `${payload.chatter_user_name}-${activeGaugeMilestone.keyword}`
        )
      ) {
        setGaugeValue((prevValue) => {
          const newValue = Math.min(
            prevValue + chatToGaugeMultiplier.current,
            100
          );
          return newValue;
        });
        gaugeRegisteredChatters.current.push(
          `${payload.chatter_user_name}-${activeGaugeMilestone.keyword}`
        );
      }
    }
  };

  useEffect(() => {
    const activeGaugeMilestone =
      gaugeMilestones.current[activeGaugeMilestoneIdx.current];
    console.log(activeGaugeMilestone);
    console.log(gaugeValue);
    if (activeGaugeMilestone)
      console.log(gaugeValue, activeGaugeMilestone.value);
    if (activeGaugeMilestone && gaugeValue >= activeGaugeMilestone.value) {
      console.log("border reached");
      if (activeGaugeMilestone.assetToPlay.type === "videoAnim") {
        enqueueAction(
          "video",
          handlePlayVideoAnim,
          convertScheduleAssetToEvent(activeGaugeMilestone.assetToPlay)
        );
      }
      activeGaugeMilestoneIdx.current += 1;
      if (activeGaugeMilestoneIdx.current === gaugeMilestones.current.length) {
        activeGaugeMilestoneIdx.current = -1;
      }
    }
  }, [gaugeValue]);

  const startCamera = async () => {
    const commonVideoSettings = {
      width: {
        min: 640,
        ideal: camWidth,
        max: 3840,
      },
      height: {
        min: 360,
        ideal: camHeight,
        max: 2160,
      },
      frameRate: {
        min: 15,
        ideal: camFPS,
        max: 60,
      },
    };

    // Determine the camera to use
    const cameraToUse = camLabel;
    const filteredCam = allCameraDevices.find(
      (cam) => cam.label === cameraToUse
    );

    // Adjust constraints based on available devices and specific requirements
    let constraints = {
      video: {
        ...commonVideoSettings,
        ...(filteredCam && {
          deviceId: { exact: filteredCam.deviceId },
          frameRate: { ...commonVideoSettings.frameRate, max: 60 }, // Adjust max frameRate for specific devices if needed
        }),
      },
    };

    snapSession?.pause();
    // Stop the previous stream if it exists
    if (userCameraVideoRef.current && userCameraVideoRef.current.srcObject) {
      const mediaStream = userCameraVideoRef.current.srcObject;
      if (mediaStream instanceof MediaStream) {
        mediaStream.getTracks().forEach((track) => track.stop());
      }
      userCameraVideoRef.current.srcObject = null;
    }
    await navigator.mediaDevices
      .getUserMedia(constraints)
      .then(async (stream) => {
        argumentIsMissing.current = false;
        if (userCameraVideoRef.current) {
          userCameraVideoRef.current.srcObject = stream;
        }

        argumentIsMissing.current = false;
        let { width: actualWidth = 1920, height: actualHeight = 1080 } = stream
          .getTracks()[0]
          .getSettings();
        const elementsToUpdate = [
          userCameraVideoRef.current,
          foregroundVideoRef.current,
          backgroundVideoRef.current,
          backgroundVideoCanvasRef.current,
          messageTempDivRef.current,
          chatGaugeRef.current,
          calibrationImage.current,
          extensionPlayerInfoRef.current,
        ];
        console.log(actualHeight, actualWidth);

        elementsToUpdate.forEach((element) =>
          updateElementDimensions(element, actualWidth, actualHeight)
        );
        setCamWidth(actualWidth);
        setCamHeight(actualHeight);

        if (userCameraVideoRef.current) {
          userCameraVideoRef.current.onloadedmetadata = async function (e) {
            userCameraVideoRef.current?.play();
            console.log("trying cam");
            if (userCameraVideoRef.current) {
              const source = await createVideoSource(
                userCameraVideoRef.current
              );
              if (snapSession) {
                await snapSession.setSource(source);
                snapSession.play();
                console.log("done thing");
              }
            }
            const response = await axios.post(
              `${apiUrl(endPoints.reportSuccessfulSetup)}/${twitchId}`
            );
          };
        }
      });
  };

  const initializeWebSocket = () => {
    let newSocket: WebSocket;
    let attemptCount = 0;
    let heartbeatInterval: NodeJS.Timeout;

    const connectWebSocket = () => {
      const mongoId = id?.split("&")[0];
      const twitchId = id?.split("&")[1];
      newSocket = new WebSocket(
        `${
          statics.WEBSOCKET_URL
        }?userId=${mongoId}&twitchId=${twitchId}&origin=${origin || "cam"}`
      );
      setSocket(newSocket);

      newSocket.onopen = () => {
        console.log("Socket connected");
        attemptCount = 0;

        // Start heartbeat when connection opens
        heartbeatInterval = setInterval(() => {
          if (newSocket?.readyState !== WebSocket.OPEN) {
            // If socket isn't open, clear interval and try to reconnect
            console.log("reconnectiong");
            clearInterval(heartbeatInterval);
            connectWebSocket();
          }
        }, 30000); // 30 seconds
      };

      newSocket.onclose = (e) => {
        console.log("Socket disconnected ----> ", e.reason, e.code);
        clearInterval(heartbeatInterval); // Clean up heartbeat
        attemptReconnection();
      };

      newSocket.onerror = (error) => {
        console.log("WebSocket error: ", error);
        clearInterval(heartbeatInterval); // Clean up heartbeat
      };
    };

    const attemptReconnection = () => {
      if (attemptCount >= 3) {
        console.log("Reconnection attempts exceeded");
        return;
      }
      attemptCount++;
      setTimeout(() => {
        console.log("Attempting to reconnect...");
        connectWebSocket();
      }, 5000);
    };

    setTimeout(() => {
      connectWebSocket();
    }, 1000);

    // Return cleanup function
    return () => {
      clearInterval(heartbeatInterval); // Clean up heartbeat
      newSocket?.close();
    };
  };

  const handleSwitchObsScene = (
    from: string,
    to: string,
    isInitialSwitch: boolean
  ) => {
    if (!window.hasOwnProperty("obsstudio")) return;
    console.log(from, to, isInitialSwitch, obsSceneBlocker.current);
    // @ts-ignore
    window.obsstudio.getCurrentScene((currentScene: any) => {
      const isCurrentSceneMatch = currentScene?.name === from;
      const shouldSwitch = isInitialSwitch || !obsSceneBlocker.current;

      if (isCurrentSceneMatch && shouldSwitch) {
        // @ts-ignore
        window.obsstudio.setCurrentScene(to);
        obsSceneBlocker.current = false;
      } else if (isInitialSwitch) {
        obsSceneBlocker.current = true;
      }
    });
  };

  const getObsScenes = async () => {
    if (!window.hasOwnProperty("obsstudio")) return;
    try {
      const getControlLevel = (): Promise<number> =>
        new Promise((resolve) => {
          //@ts-ignore
          window.obsstudio.getControlLevel(resolve);
        });

      const getScenes = (): Promise<string[]> =>
        new Promise((resolve) => {
          //@ts-ignore
          window.obsstudio.getScenes(resolve);
        });

      const level = await getControlLevel();
      obsControlLevelRef.current = level;

      let availableScenes: string[] = [];
      if (level >= 4) {
        console.log("Sufficient settings for spotlight");
        availableScenes = await getScenes();
        console.log("scenes", availableScenes);
      }

      console.log("Availablescenese", availableScenes);
      const mongoId = id?.split("&")[0];
      const twitchId = id?.split("&")[1];
      const params = {
        availableObsScenes: availableScenes,
        userId: twitchId,
        mongoID: mongoId,
      };

      try {
        const response = await axios.post(
          apiUrl(endPoints.settings) + "?notifyBrowserSource=false",
          params
        );
        console.log(response.data);
      } catch (error) {
        console.error("Error:", error);
      }
    } catch (err) {
      console.log("error", err);
    }
  };

  const genAIService: RemoteApiService = {
    apiSpecId: "a0377991-d59f-487d-b8fe-80b05fe1e9b2",

    getRequestHandler(request: RemoteApiRequest, lens: Lens) {
      if (request.endpointId !== "get_image") return;

      return (reply) => {
        // Add headers that mimic a browser request
        const requestOptions = {
          method: "GET",
          headers: {
            "User-Agent":
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/91.0.4472.124 Safari/537.36",
            Accept: "image/webp,image/apng,image/*,*/*;q=0.8",
            "Accept-Language": "en-US,en;q=0.9",
            "Cache-Control": "no-cache",
            Pragma: "no-cache",
          },
          mode: "cors" as RequestMode, // Explicitly set CORS mode
        };

        // Function to handle fetch errors and retry if needed
        const fetchWithRetry = async (
          url: string,
          retries = 3
        ): Promise<Response> => {
          try {
            const response = await fetch(url, requestOptions);
            if (!response.ok) {
              throw new Error(`HTTP error! status: ${response.status}`);
            }
            return response;
          } catch (error) {
            if (retries > 0) {
              // Wait for 1 second before retrying
              await new Promise((resolve) => setTimeout(resolve, 1000));
              return fetchWithRetry(url, retries - 1);
            }
            throw error;
          }
        };

        fetchWithRetry(statics.CLOUDFRONT_BASE_URL + customImageTexture.current)
          .then((res) => res.blob())
          .then((blob) => blob.arrayBuffer())
          .then((arrayBuffer) =>
            reply({
              status: "success",
              metadata: {},
              body: new Uint8Array(arrayBuffer),
            })
          )
          .catch((error) => {
            console.error("Image fetch error:", error);
            reply({
              status: "serverError",
              metadata: {},
              body: new TextEncoder().encode(
                `Error fetching image: ${error.message}`
              ),
            });
          });
      };
    },
  };
  console.log(genAIService.apiSpecId);

  // Define your token type properly
  const REMOTE_API_SERVICES_TOKEN = Symbol("REMOTE_API_SERVICES");
  const remoteApiServiceFactory = Injectable(
    "remoteApiServices",
    ["remoteApiServices"] as const,
    (services: RemoteApiServices) => [...services, genAIService]
  );

  const myExtension = (container: PublicContainer) =>
    container.provides(remoteApiServiceFactory);
  const initSnapCamera = async () => {
    const apiToken = statics.SNAP_TOKEN;
    const groupId = statics.SNAP_GROUP_ID;
    if (apiToken && groupId && userCameraVideoRef.current) {
      document.body.click();
      const cameraKitObj = await bootstrapCameraKit({ apiToken }, myExtension);
      const newSession = await cameraKitObj.createSession();
      const canvas = userCameraVideoRef.current;
      if (canvas) {
        canvas.replaceWith(newSession.output.live);
      }
      setSnapSession(newSession);
      setCameraKit(cameraKitObj);
    }
  };

  const loadInitialLens = async () => {
    if (cameraKit && snapSession) {
      const lens = await cameraKit.lensRepository.loadLens(
        "72cf4f73-83e9-4ea5-8444-10402ea8593b",
        "4c80b9df-2d2f-4a43-a71d-fe182fda4726"
      );
      snapSession.applyLens(lens);

      setShowSnapAttribution(true);
      setTimeout(async () => {
        await snapSession.removeLens();
        setShowSnapAttribution(false);
        setInitialLensShown(true);
      }, 8 * 1000);
    }
  };

  const maybeLoadSnapFilter = async () => {
    const groupId = statics.SNAP_GROUP_ID;
    if (activeSnapfilter.id && cameraKit && groupId && snapSession) {
      const lens = await cameraKit.lensRepository.loadLens(
        activeSnapfilter.id,
        groupId
      );
      const launchData: LensLaunchData = {
        launchParams: activeSnapfilter.launchParams,
      };
      await snapSession.removeLens();
      snapSession.applyLens(lens, launchData);
      setShowSnapAttribution(true);
      if (
        activeSnapfilter.snapLensTime &&
        activeSnapfilter.snapLensTime !== 0
      ) {
        setTimeout(async () => {
          await snapSession.removeLens();
          setShowSnapAttribution(false);
          setToDefault();
        }, activeSnapfilter.snapLensTime * 1000);
      }
    } else if (cameraKit && groupId && snapSession) {
      await snapSession.removeLens();
      setShowSnapAttribution(false);
      setToDefault();
    }
  };

  useEffect(() => {
    if (!initialLensShown) {
      loadInitialLens();
    } else {
      maybeLoadSnapFilter();
    }
  }, [activeSnapfilter, cameraKit, snapSession, initialLensShown]);

  useEffect(() => {
    const cleanupWebSocket = initializeWebSocket(); // Start WebSocket and get cleanup function
    initSnapCamera();
    getMediaDevices();
    getAllSettings();
    getObsScenes();
    if (
      !origin ||
      (origin &&
        origin !== OriginTypes.ADS_CAMPAIGNS &&
        origin !== OriginTypes.ADMIN)
    )
      getActiveUserCampaigns();
    return () => {
      cleanupWebSocket(); // Cleanup WebSocket when component unmounts
    };
  }, []);

  useEffect(() => {
    if (socket) {
      socket.onmessage = async (event: MessageEvent) => {
        const serverData: WebSocketMessage = JSON.parse(event.data);
        console.log(serverData);
        if (serverData.typeOfEvent in videoEventActions) {
          const action = videoEventActions[serverData.typeOfEvent];
          enqueueAction("video", action, serverData.params);
        } else if (serverData.typeOfEvent === EventTypes.UPDATE_SETTINGS) {
          handleUpdateSettings(serverData.params);
        } else if (serverData.typeOfEvent === EventTypes.CALIB_ADDED) {
          showCalibrationImg(serverData.params);
        } else if (serverData.typeOfEvent === EventTypes.CALIB_REMOVED) {
          removeCalibrationImg();
        } else if (serverData.typeOfEvent === EventTypes.CHAT_MESSAGE) {
          handleIncomingChatMessage(serverData.params);
        } else if (serverData.typeOfEvent === EventTypes.SHOW_EXT_PLAYER_INFO) {
          setShowPlayerInfo(true);
        } else if (serverData.typeOfEvent === EventTypes.HIDE_EXT_PLAYER_INFO) {
          setShowPlayerInfo(false);
        } else if (serverData.typeOfEvent === EventTypes.CAMPAIGN_ACTIVITY) {
          setUserCampaigns([]);
          setChatToGauge({
            isThemeHidden: false,
            theme: "",
            themePositions: null,
          });
          clearAllTimeouts();
          getActiveUserCampaigns();
        } else if (serverData.typeOfEvent === EventTypes.CHAT_TO_GAUGE_THEME) {
          const isThemeHidden = serverData.params.isThemeHidden;
          setChatToGauge({ ...chatToGauge, isThemeHidden });
        } else if (serverData.typeOfEvent === EventTypes.SNAPCHAT_FILTER) {
          handleActivateSnapFilter(serverData.params);
        } else if (serverData.typeOfEvent === EventTypes.REMOVE_SNAPLENS) {
          setActiveSnapFilter({
            id: "",
            snapLensTime: 0,
            launchParams: {},
          });
        } else {
          console.log("unkown message");
        }
      };
    }
  }, [socket]);

  const StyledMessage = styled("h2")`
    position: absolute;
    top: ${editorSettings?.textPosition?.y || 0}% !important;
    left: ${editorSettings?.textPosition?.x || 0}% !important;
    padding: 0;
    font-family: "${editorSettings?.fontFamily}", sans-serif !important;
    font-weight: ${editorSettings?.fontThickness?.split("|")[1]} !important;
    letter-spacing: ${editorSettings?.letterSpacing} !important;
    font-size: ${Number(editorSettings?.fontSize) *
    (window.innerWidth <= 1280 ? 3.63 : 5.45)}px !important;
    margin-bottom: 0;
    color: ${editorSettings?.color ?? "#ffffff"};
    max-width: 50%;
    width: 100%;
    display: flex;
    justify-content: center;
    white-space: pre-wrap;
    -webkit-text-stroke: ${editorSettings?.stroke
      ? `1px ${editorSettings?.stroke}`
      : ""};
    transform: rotateX(${editorSettings?.rotations?.x}deg)
      rotateY(${editorSettings?.rotations?.y}deg)
      rotateZ(${editorSettings?.rotations?.z}deg);
    transformstyle: preserve-3d;
    backfacevisibility: visible;
  `;

  const BrandImageComponent = styled("img")`
    position: absolute;
    top: ${messageTemplate?.type === "promo-code"
      ? editorSettings?.imagePosition?.y
      : 0}% !important;
    left: ${messageTemplate?.type === "promo-code"
      ? editorSettings?.imagePosition?.x
      : 0}% !important;
    width: ${extensionPlayerInfoRef.current &&
    extensionPlayerInfoRef.current.clientWidth <= 1280
      ? editorSettings?.imageSize?.split("|")[2]
      : editorSettings?.imageSize?.split("|")[3]};
    height: ${editorSettings?.imageSize?.split("|")[2]};
    object-fit: cover;
    display: ${editorSettings?.imagePlacement?.split("*")[0] || "block"};
    justify-content: center;
  `;

  const StyledMessageForTwitchAlerts = styled("h2")`
    position: absolute;
    top: ${editorSettings?.position?.y || 0}% !important;
    left: ${editorSettings?.position?.x || 0}% !important;
    padding: 0;
    font-family: "${editorSettings?.fontFamily}" !important;
    font-weight: ${editorSettings?.fontThickness?.split("|")[1]} !important;
    letter-spacing: ${editorSettings?.letterSpacing} !important;
    font-size: ${Number(editorSettings?.fontSize) *
    (window.innerWidth <= 1280 ? 3.63 : 5.45)}px !important;
    margin-bottom: 0;
    color: ${editorSettings?.color ?? "#ffffff"};
    animation-delay: ${editorSettings?.delay ?? 0.5}s !important;
    max-width: 50%;
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
    white-space: pre-wrap;
    transform: rotateX(${editorSettings?.rotations?.x}deg)
      rotateY(${editorSettings?.rotations?.y}deg)
      rotateZ(${editorSettings?.rotations?.z}deg);
    transformstyle: preserve-3d;
    backfacevisibility: visible;
  `;

  const memoizedMessageTemp = useMemo(() => {
    return (
      <div
        ref={messageTempDivRef}
        className={`mes_temp-preview ${
          messageTemplate.message || brandImgSrc ? "fade-in" : "fade-out"
        }`}
        style={{
          position: "absolute",
          left: 0,
          top: 0,
          width: "100%",
          height: "100%",
          transformStyle: "preserve-3d" as const,
          perspective: "1000px",
        }}
      >
        {messageTemplate?.type === "twitch-alert" ? (
          <>
            <StyledMessageForTwitchAlerts className="main_text">
              {messageTemplate?.message}
            </StyledMessageForTwitchAlerts>
          </>
        ) : (
          <>
            <StyledMessage className="main_text">
              {messageTemplate.message}
            </StyledMessage>
            {brandImgSrc && (
              <BrandImageComponent
                src={statics.CLOUDFRONT_BASE_URL + brandImgSrc}
              />
            )}
          </>
        )}
      </div>
    );
  }, [messageTemplate]);

  return (
    <>
      <video
        ref={backgroundVideoRef}
        style={{ position: "absolute", top: 0, left: 0 }}
        hidden
        preload={"auto"}
      ></video>
      <video
        ref={userCameraVideoRef}
        style={{ position: "absolute", top: 0, left: 0 }}
      ></video>
      <canvas
        ref={snapCanvasRef}
        style={{ position: "absolute", top: 0, left: 0 }}
      ></canvas>
      <canvas
        style={{ position: "absolute", top: 0, left: 0 }}
        ref={backgroundVideoCanvasRef}
      ></canvas>
      <video
        ref={foregroundVideoRef}
        style={{ position: "absolute", top: 0, left: 0 }}
        preload={"auto"}
      ></video>
      <img
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          objectFit: "cover",
          opacity: 0.3,
        }}
        ref={calibrationImage}
        id="calibration-image"
        alt=""
        hidden
      />
      <div className="extension-player_div" ref={extensionPlayerInfoRef}>
        {showPlayerInfo && extensionPlayerInfo.length > 0 && (
          <div className="extension-player_insider">
            {extensionPlayerInfo.map((info) => (
              <>
                <div
                  className={`extension-player_wrapper ${
                    showPlayerInfo && extensionPlayerInfo.length > 0
                      ? "come-in"
                      : ""
                  }`}
                >
                  <div className="play-img">
                    <img src={info?.thumbnail} alt="" />
                  </div>
                  <div className="player-info">
                    <p>
                      {info?.name} played {info?.playType}
                    </p>
                    <span>{info?.playTitle}</span>
                  </div>
                </div>
              </>
            ))}
          </div>
        )}
      </div>
      {memoizedMessageTemp}
      <div ref={chatGaugeRef} style={{ position: "absolute", left: 0, top: 0 }}>
        {gaugeValue >= 0 && (
          <>
            {chatToGauge.theme === chatGaugeThemes.GOLF_CLASH &&
              !chatToGauge.isThemeHidden && (
                <GolfClash
                  gaugeValue={gaugeValue}
                  resolution={`${camWidth}/${camHeight}`}
                  positions={chatToGauge.themePositions}
                />
              )}
          </>
        )}
      </div>
      {permissionDenied || notReadable ? (
        <div style={{ position: "absolute", left: 0, top: 0 }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: camWidth,
              height: camHeight,
            }}
          >
            <Box
              sx={{
                maxWidth: 650,
                width: "100%",
                border: "1px solid #7cd959",
                borderRadius: 6,
                padding: "25px",
              }}
            >
              <Typography
                variant="h3"
                align="center"
                sx={{
                  fontWeight: 700,
                  marginBottom: "20px",
                  textTransform: "uppercase",
                  color: "#7cd959",
                  borderBottom: "1px solid #7cd959",
                  paddingBottom: "20px",
                }}
              >
                {permissionDenied ? "OBS Flag" : "Camera Status"}
              </Typography>
              {/* Step 1: Permission Status */}
              <Box display="flex" alignItems="center" mb={2}>
                <IconButton disabled={permissionDenied}>
                  {permissionDenied ? (
                    <Close color="error" />
                  ) : (
                    <CheckCircleIcon sx={{ color: "rgb(124, 217, 89)" }} />
                  )}
                </IconButton>

                <span style={{ marginLeft: "8px", fontSize: 20 }}>
                  {permissionDenied ? (
                    <span
                      style={{
                        color: "#d32f2f",
                      }}
                    >
                      Could not find OBS flag. No camera permission.
                    </span>
                  ) : (
                    <span
                      style={{
                        color: "#7cd959",
                      }}
                    >
                      OBS flag works. Camera permission granted.
                    </span>
                  )}
                </span>
              </Box>
              {permissionDenied && (
                <>
                  <Typography className="troubleshoot-title">
                    Troubleshoot Options
                  </Typography>
                  <ul className="troubleshoot-options">
                    <li>
                      Make sure you start the same OBS shortcut in which you
                      added the flag in its properties.
                    </li>
                    <li>
                      Shortcut on your Desktop and the one you find when you use
                      windows search are separate. You can add flag to both.
                    </li>
                    <li>Make sure you have added a space before the flag.</li>
                  </ul>
                </>
              )}

              {/* Step 2: Camera Usage Status */}
              <Box display="flex" alignItems="center">
                <IconButton disabled={true}>
                  {notReadable && <Close color="error" />}
                </IconButton>
                <span style={{ marginLeft: "8px", fontSize: 20 }}>
                  {notReadable && (
                    <span
                      style={{
                        color: "#d32f2f",
                      }}
                    >
                      Camera is used by another application
                    </span>
                  )}
                </span>
              </Box>
              {notReadable && (
                <>
                  <Typography className="troubleshoot-title">
                    Troubleshoot Options
                  </Typography>
                  <ul className="troubleshoot-options">
                    <li>
                      Make sure the camera is not used by another application.
                    </li>
                    <li>Check your camera indicator light is off.</li>
                    <li>
                      Check all OBS scenes if any scene uses your camera or not.
                    </li>
                  </ul>
                </>
              )}
            </Box>
          </Box>
        </div>
      ) : null}
      <SnapAttrComponent
        containerHeight={camHeight}
        containerWidth={camWidth}
        showSnapAttribution={showSnapAttribution}
      />
    </>
  );
};

export default StreamfogCam;
