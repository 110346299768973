import React, { useState, useEffect } from 'react';
import { getImageUrl } from '../../utils/getPaths';

interface SnapAttrComponentProps {
  containerWidth?: string | number;
  containerHeight?: string | number;
  showSnapAttribution: boolean;
}

const SnapAttrComponent: React.FC<SnapAttrComponentProps> = ({
  containerWidth = '100%',
  containerHeight = '100vh',
  showSnapAttribution
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [shouldRender, setShouldRender] = useState(showSnapAttribution);

  useEffect(() => {
    if (showSnapAttribution) {
      setShouldRender(true);
      setTimeout(() => setIsVisible(true), 50);
    } else {
      setIsVisible(false);
      const timer = setTimeout(() => setShouldRender(false), 1000); // Wait for fade out
      return () => clearTimeout(timer);
    }
  }, [showSnapAttribution]);

  if (!shouldRender) return null;

  return (
    <div
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: containerWidth,
        height: containerHeight,
        overflow: 'hidden'
      }}
    >
      <img
        src={getImageUrl('snap_xl.png')}
        alt="Snap Attribution"
        style={{
          position: 'absolute',
          bottom: '5%',
          right: '5%',
          width: '10%',
          height: 'auto',
          opacity: isVisible ? 1 : 0,
          transition: 'opacity 1s ease-in-out'
        }}
      />
    </div>
  );
};

export default SnapAttrComponent;