export const statics = {
  BASE_URL: process.env.REACT_APP_API_STAGGING_URL,
  BASE_URL_V2: process.env.REACT_APP_API_URL_V2,
  CLIENT_ID: process.env.REACT_APP_CLIENT_ID,
  CLIENT_SECRET: process.env.REACT_APP_CLIENT_SECRET,
  BASE_SEGMENTATION_URL: process.env.REACT_APP_SEGMENTATION_URL,
  REDIRECT_URI: process.env.REACT_APP_REDIRECT_URI,
  IMAGE_URL: process.env.REACT_APP_IMAGE_URL,
  WEBSOCKET_URL: process.env.REACT_APP_WEBSOCKET_URL,
  DASHBOARD_URL: process.env.REACT_APP_DASHBOARD_URL,
  CLOUDFRONT_BASE_URL: process.env.REACT_APP_CLOUDFRONT_BASE_URL as string,
  SNAP_TOKEN: process.env.REACT_APP_SNAP_TOKEN,
  SNAP_GROUP_ID: process.env.REACT_APP_SNAP_GROUP_ID,
  SUPABASE_URL: process.env.REACT_APP_SUPABASE_URL,
  SUPABASE_KEY: process.env.REACT_APP_SUPABASE_KEY,
  SUPABASE_FILE_URL: process.env.REACT_APP_SUPABASE_FILE_URL,
};

export const chatGaugeThemes = { GOLF_CLASH: "golf-clash" };
