import { useEffect, useMemo, useRef, useState } from "react";
import { statics } from "../../utils/statics";
import { useParams } from "react-router-dom";
import PlayGIFOnce from "./PlayGIFOnce";
import styled from "@emotion/styled";
import "../../TextAnimations.scss";
import { supabase } from "../../supabase-client";
import axios from "axios";
import { apiUrlV2 } from "../../utils/getPaths";
import { endPoints } from "../../utils/apiEndPoints";

const messageTempInit = { type: "", message: "" };

interface Payload {
  [key: string]: any; // This can be more specific based on the actual payload structure
}

const AlertWidget = () => {
  const { id } = useParams();
  const twitchId = id?.split("&")[1];
  const [socket, setSocket] = useState<WebSocket | null>(null);
  const [currentMessage, setCurrentMessage] = useState<string>("");
  const [userCampaigns, setUserCampaigns] = useState<any[]>([]);
  const foregroundVideoRef = useRef<HTMLVideoElement>(null);
  const [gifUrl, setGifUrl] = useState({
    url: "",
    regularAlertGifDuration: 5000,
  });
  const messageTempDivRef = useRef<HTMLDivElement>(null);
  const hasInteractedRef = useRef<boolean>(false);
  const [brandImgSrc, setBrandImgSrc] = useState<string>("");
  const [messageTemplate, setMessageTemplate] = useState(messageTempInit);
  const [editorSettings, setEditorSettings] = useState<any>({
    fontFamily: "Poppins",
    fontSize: 12,
    fontThickness: "Regular|400",
    effect: "Night_Lights_Effect",
    textPosition: { x: "0", y: "0" },
    imagePosition: { x: "0", y: "0" },
    letterSpacing: "0px",
    imagePlacement: "block*top",
    imageSize: "Medium|30px|65px",
    color: "#ffffff",
    stroke: null,
    rotations: { x: 0.0, y: 0.0, z: 0.0 },
  });

  const timeouts: { [key: number]: NodeJS.Timeout } = {};

  function setToDefault() {
    if (messageTempDivRef.current) {
      messageTempDivRef.current.classList.remove("fade-in");
      messageTempDivRef.current.classList.add("fade-out");
    }
    setTimeout(() => {
      setMessageTemplate(messageTempInit);
      setBrandImgSrc("");
    }, 500);
  }

  function maybeSetMessageTemplate(payload: Payload) {
    if (payload?.config?.messageTemplate?.type) {
      let { type, message } = payload.config.messageTemplate;
      if (
        payload.config.eventData &&
        message.includes("{name}") &&
        "name" in payload.config.eventData
      ) {
        message = message.replace("{name}", payload.config.eventData.name);
      }
      if (
        payload.config.eventData &&
        message.includes("{amount}") &&
        "amount" in payload.config.eventData
      ) {
        message = message.replace("{amount}", payload.config.eventData.amount);
      }
      console.log("setting messageTemplate", type, message);
      const msgTempStngs = payload.config.messageTemplateSettings;

      console.log("setting messageTemplateSetings", msgTempStngs);
      setTimeout(() => {
        setEditorSettings(msgTempStngs);
        setMessageTemplate({ type, message });
        if (msgTempStngs.duration) {
          setTimeout(() => {
            setToDefault();
          }, (msgTempStngs.duration ? msgTempStngs.duration : 5) * 1000);
        }
      }, (msgTempStngs.delay ? msgTempStngs.delay : 0) * 1000);
    }

    if (payload?.config?.brandImg) {
      setBrandImgSrc(payload.config.brandImg);
    }
  }

  // Initialize autoplay capability
  useEffect(() => {
    // Simulate user interaction on component mount
    const simulateInteraction = () => {
      if (!hasInteractedRef.current) {
        console.log("simulated click");
        document.body.click();
        hasInteractedRef.current = true;
      }
    };

    // Wait for a brief moment to ensure DOM is ready
    setTimeout(simulateInteraction, 100);
  }, []);

  useEffect(() => {
    if (socket) {
      socket.onmessage = async (event: MessageEvent) => {
        const serverData: any = JSON.parse(event.data);
        console.log(serverData);

        if (serverData.typeOfEvent === "regularAlert") {
          // console.log(serverData, "server");
          const alertType = serverData.params.regularAlert.split(".").pop();
          if (alertType === "gif") {
            const { regularAlert, regularAlertGifDuration } = serverData.params;
            setGifUrl({
              url: statics.CLOUDFRONT_BASE_URL + regularAlert,
              regularAlertGifDuration: regularAlertGifDuration,
            });
            maybeSetMessageTemplate(serverData.params);
          } else {
            playRegularAlert(serverData.params);
          }
          // maybeSetMessageTemplate(serverData.params);
        } else if (serverData.typeOfEvent === "playVideoAnim") {
          // This is a hack, Trello ticket already created to fix this
          const foregroundUrl = serverData.params.foreground.replace(
            statics.CLOUDFRONT_BASE_URL,
            ""
          );
          const params = {
            regularAlert: foregroundUrl,
            config: serverData.params.config,
          };
          playRegularAlert(params);

          if (serverData.params.campaignId) {
            const campaign = userCampaigns.find(
              (campaign) => campaign.id === serverData.params.campaignId
            );
            if (campaign && campaign.automaticChatMessage) {
              sendTwitchChatMessage(campaign.automaticChatMessage);
            }
          }
        }

        setCurrentMessage(serverData);
      };
    }
  }, [socket]);

  const playRegularAlert = (payload: any) => {
    if (foregroundVideoRef.current) {
      foregroundVideoRef.current.src =
        statics.CLOUDFRONT_BASE_URL + payload.regularAlert;
      foregroundVideoRef.current.oncanplaythrough = () => {
        if (foregroundVideoRef.current) {
          foregroundVideoRef.current.play();
          maybeSetMessageTemplate(payload);
        }
      };
      foregroundVideoRef.current.onended = () => {
        if (foregroundVideoRef.current) {
          foregroundVideoRef.current.removeAttribute("src");
          foregroundVideoRef.current.load();
          setToDefault();
        }
      };
    }
  };

  const initializeWebSocket = () => {
    let newSocket: WebSocket;
    let attemptCount = 0;
    let heartbeatInterval: NodeJS.Timeout;

    const connectWebSocket = () => {
      const mongoId = id?.split("&")[0];
      const twitchId = id?.split("&")[1];
      newSocket = new WebSocket(
        `${statics.WEBSOCKET_URL}?userId=${mongoId}&twitchId=${twitchId}&origin=alerts`
      );
      setSocket(newSocket);

      newSocket.onopen = () => {
        console.log("Socket connected");
        attemptCount = 0;

        // Start heartbeat when connection opens
        heartbeatInterval = setInterval(() => {
          if (newSocket?.readyState !== WebSocket.OPEN) {
            // If socket isn't open, clear interval and try to reconnect
            console.log("reconnectiong");
            clearInterval(heartbeatInterval);
            connectWebSocket();
          }
        }, 30000); // 30 seconds
      };

      newSocket.onclose = (e) => {
        console.log("Socket disconnected ----> ", e.reason, e.code);
        clearInterval(heartbeatInterval); // Clean up heartbeat
        attemptReconnection();
      };

      newSocket.onerror = (error) => {
        console.log("WebSocket error: ", error);
        clearInterval(heartbeatInterval); // Clean up heartbeat
      };
    };

    const attemptReconnection = () => {
      if (attemptCount >= 3) {
        console.log("Reconnection attempts exceeded");
        return;
      }
      attemptCount++;
      setTimeout(() => {
        console.log("Attempting to reconnect...");
        connectWebSocket();
      }, 5000);
    };

    setTimeout(() => {
      connectWebSocket();
    }, 1000);

    // Return cleanup function
    return () => {
      clearInterval(heartbeatInterval); // Clean up heartbeat
      newSocket?.close();
    };
  };

  useEffect(() => {
    const cleanupWebSocket = initializeWebSocket(); // Start WebSocket and get cleanup function
    getActiveUserCampaigns();
    return () => {
      cleanupWebSocket(); // Cleanup WebSocket when component unmounts
    };
  }, []);

  const StyledMessageForTwitchAlerts = styled("h2")`
    position: absolute;
    top: ${editorSettings?.position?.y || 0}% !important;
    left: ${editorSettings?.position?.x || 0}% !important;
    padding: 0;
    font-family: "${editorSettings?.fontFamily}" !important;
    font-weight: ${editorSettings?.fontThickness?.split("|")[1]} !important;
    letter-spacing: ${editorSettings?.letterSpacing} !important;
    font-size: ${Number(editorSettings?.fontSize) *
    (window.innerWidth <= 1280 ? 3.63 : 5.45)}px !important;
    margin-bottom: 0;
    color: ${editorSettings?.color ?? "#ffffff"};
    max-width: 50%;
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
    white-space: pre-wrap;
    transform: rotateX(${editorSettings?.rotations?.x}deg)
      rotateY(${editorSettings?.rotations?.y}deg)
      rotateZ(${editorSettings?.rotations?.z}deg);
    transformstyle: preserve-3d;
    backfacevisibility: visible;
  `;

  const StyledMessage = styled("h2")`
    position: absolute;
    top: ${editorSettings?.textPosition?.y || 0}% !important;
    left: ${editorSettings?.textPosition?.x || 0}% !important;
    padding: 0;
    font-family: "${editorSettings?.fontFamily}", sans-serif !important;
    font-weight: ${editorSettings?.fontThickness?.split("|")[1]} !important;
    letter-spacing: ${editorSettings?.letterSpacing} !important;
    font-size: ${Number(editorSettings?.fontSize) *
    (window.innerWidth <= 1280 ? 3.63 : 5.45)}px !important;
    margin-bottom: 0;
    color: ${editorSettings?.color ?? "#ffffff"};
    max-width: 50%;
    width: 100%;
    display: flex;
    justify-content: center;
    white-space: pre-wrap;
    -webkit-text-stroke: ${editorSettings?.stroke
      ? `1px ${editorSettings?.stroke}`
      : ""};
    transform: rotateX(${editorSettings?.rotations?.x}deg)
      rotateY(${editorSettings?.rotations?.y}deg)
      rotateZ(${editorSettings?.rotations?.z}deg);
    transformstyle: preserve-3d;
    backfacevisibility: visible;
  `;

  const BrandImageComponent = styled("img")`
    position: absolute;
    top: ${messageTemplate?.type === "promo-code"
      ? editorSettings?.imagePosition?.y
      : 0}% !important;
    left: ${messageTemplate?.type === "promo-code"
      ? editorSettings?.imagePosition?.x
      : 0}% !important;
    width: ${editorSettings?.imageSize?.split("|")[2]};
    height: ${editorSettings?.imageSize?.split("|")[2]};
    object-fit: cover;
    display: ${editorSettings?.imagePlacement?.split("*")[0] || "block"};
    justify-content: center;
  `;

  const getActiveUserCampaigns = async () => {
    const { data, error } = await supabase
      .from("userCampaigns")
      .select("*")
      .eq("userId", twitchId)
      .eq("isActive", true)
      .eq("targetChannel", "alerts");
    if (error) {
      return;
    }
    setUserCampaigns(data);
  };

  useEffect(() => {
    console.log(userCampaigns);
    if (userCampaigns?.length > 0) {
      userCampaigns.forEach((campaign) => {
        if (campaign.type === "intervalPlay") {
          randomAssetPlayForCampaigns(campaign);
        }
      });
    }
  }, [userCampaigns]);

  const sendTwitchChatMessage = async (message: string) => {
    const response = await axios.post(
      `${apiUrlV2(endPoints.sendChatMessage)}`,
      {
        twitchId: twitchId,
        message: message,
      }
    );
    console.log(response);
  };

  const randomAssetPlayForCampaigns = async (campaign: any) => {
    console.log(campaign);
    const randomAsset =
      campaign.assets[Math.floor(Math.random() * campaign.assets.length)];
    const interval = campaign.userConfig?.interval;

    if (interval > 0) {
      console.log(
        "Campaign Random Asset to play on " + interval + " minutes ------->> ",
        randomAsset
      );

      timeouts[interval] = setTimeout(() => {
        if (randomAsset !== null) {
          if (randomAsset.type === "videoAnim") {
            const payload = {
              regularAlert: randomAsset.rootPath + "foreground.webm",
            };
            playRegularAlert(payload);
          }
          if (campaign.automaticChatMessage) {
            sendTwitchChatMessage(campaign.automaticChatMessage);
          }

          const unifiedTemplate = {
            ...campaign?.campaignConfig?.promo_code_settings,
            color: campaign?.userConfig?.user_promo_code_color
              ? campaign?.campaignConfig?.promo_code_settings?.color
              : "#fff",
          };

          maybeSetMessageTemplate({
            config: {
              messageTemplate: {
                type: "promo-code",
                message: campaign.userConfig?.promo_code
                  ? campaign?.campaignConfig?.promo_code_settings?.template?.replace(
                      "{PROMO_CODE}",
                      campaign?.userConfig?.promo_code
                    )
                  : "",
              },
              messageTemplateSettings: unifiedTemplate,
              brandImg: campaign.brand_image,
            },
          });
          randomAssetPlayForCampaigns(campaign); // Reschedule for the next interval
        }
      }, interval * 60 * 1000);
    }
  };

  const memoizedMessageTemp = useMemo(() => {
    return (
      <div
        ref={messageTempDivRef}
        className={`mes_temp-preview ${
          messageTemplate.message || brandImgSrc ? "fade-in" : "fade-out"
        }`}
        style={{
          position: "absolute",
          opacity: 1,
          left: 0,
          top: 0,
          width: "100%",
          height: "100%",
          transformStyle: "preserve-3d" as const,
          perspective: "1000px",
        }}
      >
        {messageTemplate?.type === "twitch-alert" ? (
          <>
            <StyledMessageForTwitchAlerts className="main_text">
              {messageTemplate?.message}
            </StyledMessageForTwitchAlerts>
          </>
        ) : (
          <>
            <StyledMessage className="main_text">
              {messageTemplate?.message}
            </StyledMessage>
            {brandImgSrc && (
              <BrandImageComponent
                src={statics.CLOUDFRONT_BASE_URL + brandImgSrc}
              />
            )}
          </>
        )}
      </div>
    );
  }, [messageTemplate]);

  return (
    <div className="position-fixed inset-0 w-100 h-100">
      <PlayGIFOnce gifUrl={gifUrl} setGifUrl={setGifUrl} />
      <video
        style={{ objectFit: "cover", objectPosition: "center" }}
        className="w-100 h-100"
        ref={foregroundVideoRef}
      ></video>
      {memoizedMessageTemp}
    </div>
  );
};

export default AlertWidget;
