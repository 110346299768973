import { Box } from "@mui/material";
import React, { useEffect, useRef } from "react";
import { getImageUrl } from "../../utils/getPaths";

const GolfClash = ({
  resolution,
  gaugeValue,
  positions,
}: {
  resolution: string;
  gaugeValue: number;
  positions?: any;
}) => {
  const { meter: meterPosition } = positions ? positions : { meter: undefined };
  const rotatingArrow = useRef<HTMLImageElement>(null);
  const handleRotateMeter = (gaugeValue: number) => {
    // Map gaugeValue (0 to 100) to degree (-90 to 90)
    const degree = (gaugeValue / 100) * 180 - 90;

    if (rotatingArrow.current) {
      rotatingArrow.current.style.transform = `translateX(-50%) rotate(${degree}deg)`;
    }
  };

  useEffect(() => {
    if (gaugeValue > 0 && gaugeValue <= 100) {
      handleRotateMeter(gaugeValue);
    }
  }, [gaugeValue]);

  return (
    <Box
      className={`golf-clash-wrapper ${
        resolution === "1280/720" ? "p720" : "p1080"
      }`}
      sx={{
        position: "absolute",
        left: 0,
        top: 0,
        width: "100%",
        height: "100%",
      }}
    >
      <img
        className="qr-code"
        src={getImageUrl("chatgauge-themes/golf-clash/qr_code.png")}
      />
      <div className="gauge-meter-main" style={meterPosition ?? {}}>
        <img
          className="gauge-meter"
          src={getImageUrl("chatgauge-themes/golf-clash/gauge_meter_03.png")}
        />
        <img
          ref={rotatingArrow}
          className="arrow"
          src={getImageUrl("chatgauge-themes/golf-clash/arrow.png")}
        />
      </div>
    </Box>
  );
};

export default GolfClash;
