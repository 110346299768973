import axios from "axios";
import { toast } from "react-toastify";
import { apiUrl } from "./getPaths";
import { endPoints } from "./apiEndPoints";

export const cleanLocalStorage = () => {
  localStorage.removeItem("userinfo");
  localStorage.removeItem("access_token");
  localStorage.removeItem("refresh_token");
  localStorage.removeItem("sub_access_token");
  localStorage.removeItem("my_user_id");
  localStorage.removeItem("broadcaster_id");
  localStorage.removeItem("session_id");
  localStorage.removeItem("isStreaming");
  localStorage.removeItem("channel.events");
  localStorage.removeItem("eventsAndItsAnimations");
  // localStorage.removeItem("localsystemfolders");
  localStorage.removeItem("expressStarted");
  localStorage.removeItem("freeTrialEndsAt");
  localStorage.removeItem("isFreeTrialRunning");
  localStorage.removeItem("setFreeTrialEndedPopupTimer");
  localStorage.removeItem("stripeCustomerId");
  localStorage.removeItem("newUserRegistered");
};

export interface StreamFogPlansProps {
  name: string;
  price: string;
  benefits: any[];
  price_id?: string;
}

export const camelCaseToNormalCase = (word: string) => {
  const result = word && word.replace(/([A-Z])/g, "$1");
  const finalResult =
    result && result.charAt(0).toUpperCase() + result.slice(1);
  return finalResult;
};

export const getToaster = (
  type: "success" | "error" | "warn",
  message: string,
  options?: any
) => {
  if (type === "success") {
    return toast.success(message);
  } else if (type === "error") {
    return toast.error(message);
  } else if (type === "warn") {
    return toast.warn(message);
  } else {
    toast(message);
  }
};

export const sendDataFromHereToTwitchExtViewers = async (
  id: string,
  typeOfEvent: string,
  params?: any
) => {
  try {
    const response = await axios.post(
      apiUrl(endPoints.sendDataToTwitchExtViewers),
      {
        channelId: id?.split("&")[1],
        viewerId: id?.split("&")[1],
        role: "broadcaster",
        dataToBroadcast: {
          typeOfEvent,
          params: params && params,
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};
