import { GifReader } from "omggif";
import { useEffect, useRef } from "react";

interface Props {
  gifUrl: {
    url: string;
    regularAlertGifDuration: number;
  };
  setGifUrl: ({
    url,
    regularAlertGifDuration,
  }: {
    url: string;
    regularAlertGifDuration: number;
  }) => void;
}

const PlayGIFOnce = ({ gifUrl, setGifUrl }: Props) => {
  const imgRef = useRef<HTMLImageElement>(null);

  const calculateGifDuration = async (url: string) => {
    if (imgRef.current) {
      imgRef.current.src = url;
      imgRef.current.style.display = "block";
    }
  };

  const handleGifLoop = async () => {
    await calculateGifDuration(gifUrl.url);
    setTimeout(() => {
      if (imgRef.current) {
        imgRef.current.src = "";
        imgRef.current.style.display = "none";
        setGifUrl({ url: "", regularAlertGifDuration: 5000 });
        console.log("GIF completed one loop, source cleared, made it hidden.");
      }
    }, gifUrl.regularAlertGifDuration + 200);
  };

  useEffect(() => {
    if (gifUrl.url) {
      handleGifLoop();
    }
  }, [gifUrl.url]);

  if (!gifUrl.url) {
    return null;
  }

  return (
    <img
      style={{ objectFit: "contain", objectPosition: "center" }}
      className="w-100 h-100 position-absolute"
      ref={imgRef}
      src={gifUrl.url}
      alt="GIF"
    />
  );
};

export default PlayGIFOnce;
